/* Search Container */
.Search-Container {
    display: grid;
    grid-template-columns: 1fr 45px 45px 45px;
    grid-template-areas: 
    'Search Submit Clear Bucket'
    'Search-Errors Search-Errors Search-Errors Search-Errors'
    'Results Results Results Results';
    max-width: 1100px;
    width: 100%;
    align-items: center;
    margin-top: 1%;
    padding: 20px;
}

/* ===================================== */
/*          Search Bar                   */
/* ===================================== */

.Search-Input {
    grid-area: Search;
    height: 40px;
    padding: 10px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 10px;
    width: 100%;
    min-width: 150px;
}
.Search-Input:focus {
    outline: none;
}
.Search-Input:disabled {
    border: 0.5px solid #daddd752;
    background-color: white;
}

/* Search Icons */
.Search-Submit{
    grid-area: Submit;
    cursor: pointer;
    padding: 12px;
}
.Search-Submit:hover{
    transform: scale(1.05);
}

.Search-Clear {
    grid-area: Clear;
    cursor: pointer;
    padding: 14px;
}
.Search-Clear:hover{
    transform: scale(1.05);
}

.Search-Bucket{
    grid-area: Bucket;
    cursor: pointer;
}
.Search-Bucket img {
    padding: 8px;
}
.Search-Bucket img:hover{
    transform: scale(1.05);
}

/* Search Errors */
.Search-Errors {
    grid-area: Search-Errors;
    margin-top: 10px;
    font-weight: 500;
}

/* ===================================== */
/*          Search Results               */
/* ===================================== */

.Search-Results {
    grid-area: Results;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.Search-Results-Item {
    display: grid;
    grid-template-areas: 
    'Name Folder'
    'Metadata Metadata';
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;
    border-top: 0.5px solid #daddd752;
    box-shadow: 0px 1px 1px rgba(135, 135, 135, 0.5);
    border-radius: 5px;
    min-width: 340px;
}

.Search-Item-Name {
    grid-area: Name;
    font-size: 18px;
    font-weight: 500;
}
.Search-Item-Name a {
    text-decoration: none;
    cursor: pointer;
    color: #0C6CDE;
}

.Search-Item-Folder{
    grid-area: Folder;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.Search-Item-Folder a img{
    cursor: pointer;
    height: 30px;
}

/* Large Devices */
@media (width > 850px) {

    .Search-Item-Metadata {
        grid-area: Metadata;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 10px;
    }

}

/* Small devices */
@media (width < 851px) {

    .Search-Item-Metadata {
        grid-area: Metadata;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
        gap: 10px;
        min-width: 300px
    }

}
