/* ------------------------------------------------------ */
/*  Login Page Styling (Unauthenticated State)            */
/* ------------------------------------------------------ */


.LoginHandler-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 2%;
}

.Login-Graphic {
    max-width: 250px;
    max-height: 250px;
}

.Login-Detail{
    display: flex;
    flex-direction: column;
    gap: 20px 0px;
    margin: 25px;
    font-size: var(--body);
}

.Login-Button{
    text-align: left;
}

/* ===============================================

    Failed and Error pages

    Generic grid that supports 2 columns and 3 rows, layout is below:
        'grumpy-cat title'
        'grumpy-cat solution'
        'grumpy-cat button'
        'error error' 

=============================================== */

.LoginHandler-Failed-Container {
    display: grid; 
    text-align: left;
    padding: 15%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        'LoginHandler-Failed-Img LoginHandler-Failed-Title'
        'LoginHandler-Failed-Img LoginHandler-Failed-Solution'
        'LoginHandler-Failed-Img LoginHandler-Failed-Button'
        'LoginHandler-Failed-Error LoginHandler-Failed-Error';
}

.LoginHandler-Failed-Img{
    grid-area: LoginHandler-Failed-Img;
    max-width: 550px;
}
.LoginHandler-Failed-Title{
    grid-area: LoginHandler-Failed-Title;
    padding-left: 5%;
}
.LoginHandler-Failed-Solution{
    grid-area: LoginHandler-Failed-Solution;
    padding-left: 5%;
}
.LoginHandler-Failed-Button{
    grid-area: LoginHandler-Failed-Button;
    padding-left: 5%;
}
.LoginHandler-Failed-Error{
    grid-area: LoginHandler-Failed-Error;
    text-align: center;
    padding-top: 5%;
}

/* ===============================================
    Pending pages containers
        'pending'
        'pending-signin'
=============================================== */

.LoginHandler-Pending{
    text-align: center;
    padding: 10% 15%;
}

/* ------------------------------------------------------------ */
/** CSS Media Queries (Mobile)                                 **/
/* ------------------------------------------------------------ */

@media (max-width: 500px) {

    .Login-Graphic {
        display: none;
    }
 
}


